/* global UTIL */

/**
 * The Reader class provides a public API for configuring
 * and interacting with the Common eReader and underlying
 * Readium viewer.
 *
 * @class Reader
 * @type {Object}
 * @return {Object} the public API
 */
var Reader = (function (Reader, UTIL) {
    'use strict';

    function ReaderFactory (options) {
        var defaults = {
                pathToReader: './index.html'
            },
            sreader = UTIL.extend(defaults, options);

        /**
         * Method opens the Common eReader in a new window.
         *
         * @method _openWindow
         * @return {Object} the window object
         */
        function _openWindow (win) {
            /* jshint validthis: true */
            var tmpItemId = this.uuid = UTIL.uuid();

            // Set epub configuration in local storage.
            window.store.set(tmpItemId, sreader);

            // SET reader configuration in local storage.
            window.store.set('reader', window.store.get('reader') || {});

            if (win) {
                win.location.replace(sreader.pathToReader + '?id=' + tmpItemId);
                /* jshint validthis: true */
                this.windowObject = win;
            } else {
                /* jshint validthis: true */
                this.windowObject = window.open(sreader.pathToReader + '?id=' + tmpItemId, '_blank');
            }
            /* jshint validthis: true */
            return this.windowObject;
        }

        /**
         * Method closes the Common eReader.
         *
         * @method _closeWindow
         */
        function _closeWindow () {
            /* jshint validthis: true */
            if (this.windowObject) {
                this.windowObject.close();
            }
        }

        return UTIL.extend(sreader, {
            open: _openWindow,
            close: _closeWindow
        });
    }

    ReaderFactory.async = function (url) {
        var asyncWindow;
        if (!asyncWindow || asyncWindow.closed) {
            asyncWindow = window.open(url, '_blank');
        }
        if (asyncWindow.location.href !== url) {
            asyncWindow.location.replace(url);
        }
        return asyncWindow;
    };

    return ReaderFactory;

}(Reader, UTIL));
